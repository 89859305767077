import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { AlertTriangleIcon } from "@100mslive/react-icons";
import { Button, Loading, Text } from "@100mslive/roomkit-react";
import { hmsBackground, hmsLogo } from "../../assets/images";
import { API_CALL_STATE } from "../../constants";
import { AppAnalytics, history } from "../../helpers";
import { acceptWorkspaceInvite } from "../../store/actions";
import { currentUser } from "../../utils";

const AcceptWorkspaceInviteModal = () => {
  const dispatch = useDispatch();
  const { status: apiStatus } = useSelector(state => state.workspace);
  useEffect(() => {
    if (apiStatus.acceptInviteWorkspace.state === API_CALL_STATE.IN_PROGRESS) {
      setStatus(statusEnum.loading);
    } else if (
      apiStatus.acceptInviteWorkspace.state === API_CALL_STATE.FAILED
    ) {
      setStatus(statusEnum.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.acceptInviteWorkspace.state]);
  const acceptWorkspaceInviteHandler = data => {
    AppAnalytics.track("member.joined.workspace");
    dispatch(acceptWorkspaceInvite(data));
  };

  const statusEnum = {
    start: {
      title: "Join Workspace",
      buttonText: "Join Workspace",
      body: ({ email, name }) => (
        <div>
          {email} has invited you to join
          <br />
          <Text variant="body1" css={{ display: "inline" }}>
            "{name}"
          </Text>{" "}
          workspace
        </div>
      ),
      mainFunction: data => acceptWorkspaceInviteHandler(data),
    },
    error: {
      title: apiStatus.acceptInviteWorkspace.msg,
      body: ({ name }) => (
        <>
          {apiStatus.acceptInviteWorkspace.msg} while trying to join <br />{" "}
          <Text variant="body1" css={{ display: "inline" }}>
            {" "}
            "{name}"{" "}
          </Text>
          workspace
        </>
      ),
      mainFunction: data => acceptWorkspaceInviteHandler(data),
      icon: <AlertTriangleIcon style={{ width: "64px", height: "64px" }} />,
      instruction: "Try again with correct code",
    },
    warning: {
      title: "You do not have access to this workspace",
      body: () => (
        <>
          You're currently logged in as{" "}
          <Text css={{ display: "inline" }}>{currentUser()?.email}</Text>.<br />
          You could try switching accounts, or contact the workspace admin
        </>
      ),
      buttonText: "Log out and switch",
      mainFunction: () => {
        history.push({
          pathname: "/logout",
          search: `?redirect_url=${encodeURIComponent(window?.location?.href)}`,
        });
      },
      instruction: "Please sign in using the email that received the invite",
    },
    loading: {
      title: "Joining Workspace",
      buttonText: null,
      body: ({ name }) => (
        <>
          Please wait. You will be redirected to{" "}
          <Text variant="body1" css={{ display: "inline" }}>
            "{name}"{" "}
          </Text>
          workspace soon
        </>
      ),
      icon: <Loading style={{ width: "64px", height: "64px" }} />,
    },
    login: {
      title: "Join Workspace",
      buttonText: "Sign up or Login to Continue",
      mainFunction: () => {
        history.push({
          pathname: "/login",
          search: `?redirect_url=${encodeURIComponent(window?.location?.href)}`,
        });
      },
      instruction: "Please sign in using the email that received the invite",
    },
  };
  statusEnum.login.body = statusEnum.start.body;
  const [status, setStatus] = useState(statusEnum.start);
  const [data, setData] = useState({});
  useEffect(() => {
    const searchParams = queryString.parse(window.location.search);
    setData(searchParams);
    if (!currentUser()?.email) {
      setStatus(statusEnum.login);
    } else if (searchParams.email === currentUser()?.email) {
      setStatus(statusEnum.start);
    } else {
      setStatus(statusEnum.warning);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(
          ${hmsBackground}
        )`,
        backgroundSize: "cover",
      }}
    >
      <a href="/dashboard">
        <img src={hmsLogo} className="h-8 m-8 absolute" alt="100ms Logo" />
      </a>
      <div className="flex flex-col justify-center items-center h-screen gap-4 max-w-md mx-auto text-center">
        {status.icon}
        <Text variant="h4">{status.title}</Text>
        <Text
          variant="body1"
          css={{ color: "$on_surface_medium", fontWeight: "$regular" }}
          as="div"
        >
          {status.body({ email: data?.senderEmail, name: data?.resourceName })}
        </Text>
        {status.buttonText && (
          <Button
            css={{ w: "$100", marginTop: "$10" }}
            onClick={() => status.mainFunction(data)}
          >
            {status.buttonText}
          </Button>
        )}
        <Text variant="caption" css={{ color: "$on_surface_medium" }}>
          {status?.instruction}
        </Text>
      </div>
    </div>
  );
};
export default AcceptWorkspaceInviteModal;
