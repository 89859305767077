import React from "react";
import { defaultConferencing } from "src/data/defaultLayout";
import { Text } from "@100mslive/roomkit-react";
import { PREBUILT_COMPONENT_TITLES } from "../../helpers/constants";

function PrebuiltHandRaiseConfig() {
  return <></>;
}

export default PrebuiltHandRaiseConfig;

const defaultData =
  defaultConferencing?.["default"]?.["elements"]?.["hand_raise"];

export const handRaisesConfig = {
  ui: {
    title: PREBUILT_COMPONENT_TITLES.HAND_RAISE,
    hasSwitch: true,
    tooltipText: (
      <Text variant="caption" css={{ color: "$on_surface_high" }}>
        {`Allows participants with this role to virtually raise their hand during
        a session, indicating their intention to: request attention or
        assistance, signal a question or comment, or request to join the stage
        (if "Bring others on stage" is enabled). If "Bring others on stage" is
        enabled, raising a hand will prompt the session host or moderator to
        consider changing the participant's role, allowing them to join the
        stage and actively participate. If "Bring others on stage" is disabled,
        the hand raise will serve as a visual cue without any automatic role
        change. The session host or moderator can acknowledge the raised hand
        and address the participant's need accordingly.\n `}
      </Text>
    ),
  },
  path: "screens.conferencing.default.elements.hand_raise",
  Component: PrebuiltHandRaiseConfig,
  defaultData: defaultData,
};
