import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { truncateString } from "src/helpers";
import {
  BuildingIcon,
  CheckIcon,
  ChevronDownIcon,
  SolidCheckCircleIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Button,
  Checkbox,
  Collapsible,
  Flex,
  Input,
  Loading,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import { ClusterRegionSelectBox } from "./ClusterRegionSelect";
import { API_CALL_STATE, validationMessagesKeys } from "../../constants";
import { AppAnalytics } from "../../helpers/analytics_helper";
import {
  createWorkspace,
  fetchWorkspaces,
  switchWorkspace,
} from "../../store/workspace/actions";
import {
  currentUser,
  currentWorkspaceHipaa,
  getCurrentWorkspaceID,
} from "../../utils";
import { Messages } from "../../validations";
import WarningCard from "../Cards/WarningCard";
import MainDialog from "../Modal/MainDialog";

//TODO: move this to some util
const getOrgName = (workspace = {}) =>
  workspace.customer_name
    ? `${workspace.customer_name} Org`
    : `${workspace.email}'s Org`;

const createCategoryObject = workspaces => {
  let tempObject = {};
  const _id = getCurrentWorkspaceID();
  const workspaceIDArray = Object.keys(workspaces);
  Object.values(workspaces).forEach((workspace, i) => {
    workspace.workspaceID = workspaceIDArray[i];
    const key = getOrgName(workspace);
    if (isEmpty(tempObject[key])) {
      tempObject[key] = [{ ...workspace }];
    } else {
      tempObject[key].push(workspace);
    }
    tempObject[key].sort((a, b) => {
      return _id === a.workspaceID ? -1 : _id === b.workspaceID ? 1 : 0;
    });
  });

  const key = getOrgName(workspaces[_id]);
  const activeOrgObject = tempObject[key];
  return { [key]: activeOrgObject, ...tempObject };
};

// eslint-disable-next-line complexity
const WorkspaceCollapsible = ({ collapsed }) => {
  const [open, setOpen] = useState(false);
  const [newWorkspace, setNewWorkspace] = useState({
    name: "",
    customer_name: "",
    hipaa: false,
    currentRegion: "in",
  });
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const dispatch = useDispatch();
  const workspaceID = getCurrentWorkspaceID();
  const workspaces = useSelector(state => state.workspace.workspaces);
  const fetchAllWorkspacesStatus = useSelector(
    state => state.workspace.status.fetchAllWorkspaces
  );
  const user = currentUser();
  const region = useSelector(state => state.userInfo.region);
  const validityArray = Object.values(workspaces)
    .filter(workspace => user.customer_id === workspace.customer_id)
    .map(workspace => workspace.name);
  const toggleCreateDialog = () => setOpenCreateDialog(!openCreateDialog);
  const [invalidName, setInvalidName] = useState(false);
  useEffect(() => {
    const newWorkspaceName = newWorkspace?.name?.trim();
    setInvalidName(
      Messages(
        validationMessagesKeys.validWorkspaceName,
        newWorkspaceName,
        false,
        validityArray
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWorkspace]);

  useEffect(() => {
    if (isEmpty(workspaces)) {
      dispatch(fetchWorkspaces());
    }
  }, [dispatch, workspaces]);

  useEffect(() => {
    if (newWorkspace?.region !== region) {
      setNewWorkspace({ ...newWorkspace, currentRegion: region });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  // Close if sidebar collapses
  useEffect(() => {
    if (collapsed && open === true) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const CategoryOption = () => {
    return Object.keys(createCategoryObject(workspaces))?.map((category, i) => {
      return (
        <Flex direction="column" css={{ w: "100%" }} key={i}>
          <Tooltip title={category} side="right">
            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                p: "$md",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                c: "$on_surface_medium",
              }}
            >
              {category}
            </Text>
          </Tooltip>
          {createCategoryObject(workspaces)[category]?.map((workspace, j) => {
            return (
              <Flex
                align="center"
                key={j}
                css={{
                  minHeight: "40px",
                  p: "0 $md",
                  bg:
                    workspaceID === workspace.workspaceID ? "$primary_dim" : "",
                  "&:hover": {
                    bg: "$secondary_default",
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(switchWorkspace(workspace.workspaceID));
                  setOpen(false);
                  AppAnalytics.track("btn.clicked", {
                    btnId: "workspace.switched",
                    componentId: "sidebar",
                    switchedWorkspaceID: workspace.workspaceID,
                  });
                }}
                justify="between"
              >
                <Text
                  variant="body2"
                  css={{
                    c: "$on_surface_high",
                    py: workspace.name.length > 20 ? "$4" : "$2",
                    wordBreak: "break-word",
                  }}
                >
                  {workspace.name}
                </Text>
                {workspaceID === workspace.workspaceID ? (
                  <Box css={{ pl: "$4", c: "$white" }}>
                    <SolidCheckCircleIcon />
                  </Box>
                ) : (
                  <></>
                )}
              </Flex>
            );
          })}
          <Flex css={{ bg: "$border_bright", h: "1px" }} />
        </Flex>
      );
    });
  };

  return (
    <Flex>
      <Collapsible.Root
        className="CollapsibleRoot"
        open={open}
        onOpenChange={value => {
          if (value) {
            AppAnalytics.track("btn.clicked", {
              btnId: "Workspace.dropdown",
              componentId: "collapsible",
            });
          }
          setOpen(value);
        }}
        css={{
          w: "100%",
          justifyContent: "center",
          mt: "$10",
        }}
      >
        <Collapsible.Trigger
          css={{
            display: "flex",
            width: "88%",
            h: "$17",
            mx: "auto",
            bg: "$surface_bright !important",
          }}
          asChild
        >
          <Button
            css={{
              borderRadius: collapsed ? "$0" : "$1",
              transition: "width 0.2s ease, border-radius 0.2s ease",
              overflow: "clip",
              "& div": {
                width: "100%",
              },
              mb: "$8",
            }}
            variant="standard"
          >
            <Flex css={{ justifyContent: "space-between" }}>
              {!isEmpty(workspaces) &&
              fetchAllWorkspacesStatus === API_CALL_STATE.DONE ? (
                <Flex
                  css={{
                    justifyContent: collapsed ? "start" : "end",
                    alignItems: "center",
                  }}
                >
                  <BuildingIcon
                    height={20}
                    width={20}
                    style={{ minWidth: "20px" }}
                  />
                  <Flex
                    direction="column"
                    css={{
                      textAlign: "left",
                      maxWidth: collapsed ? "0" : "240px",
                      opacity: collapsed ? "0" : "1",
                      transition: "all 0.2s ease",
                      overflow: "hidden",
                      minWidth: "0",
                      marginLeft: "$md",
                    }}
                  >
                    <Text
                      title={workspaces[workspaceID]?.name || " "}
                      variant="body2"
                      css={{
                        fontWeight: "$semiBold",
                        c: open ? "$on_surface_low" : "$on_surface_high",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateString(workspaces[workspaceID]?.name || " ", 13)}
                    </Text>
                    <Text
                      variant="overline"
                      css={{ fontWeight: "$semiBold", c: "$on_surface_low" }}
                    >
                      {currentWorkspaceHipaa()
                        ? "HIPAA WORKSPACE"
                        : "WORKSPACE"}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Loading />
              )}
              {!collapsed && (
                <Flex
                  justify="end"
                  css={{
                    justifyContent: "flex-end",
                    c: open && "$on_surface_medium",
                    alignItems: "center",
                  }}
                >
                  <ChevronDownIcon
                    height={20}
                    width={20}
                    style={{
                      transition: "all 0.35s ease",
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Button>
        </Collapsible.Trigger>
        <Collapsible.Content
          css={{
            mx: "auto",
            w: "88%",
            bg: "$surface_bright",
            transition: "margin-top 0.3s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Flex
            direction="column"
            css={{ overflowY: "auto", maxHeight: "300px" }}
          >
            {Object.values(workspaces).length > 0 && <CategoryOption />}
          </Flex>
          <Flex
            align="center"
            justify="start"
            css={{
              padding: "$4 $md",
              my: "$4",
              alignSelf: "stretch",
              flexGrow: 0,
              "&:hover": {
                bg: "$secondary_default",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              AppAnalytics.track("btn.clicked", {
                btnId: "workspace.create",
                componentId: "sidebar",
              });
              toggleCreateDialog();
            }}
          >
            <Text variant="body2" css={{ c: "$on_surface_high" }}>
              &#43; Create Workspace
            </Text>
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
      <MainDialog
        open={openCreateDialog}
        onOpenChange={toggleCreateDialog}
        title="Create Workspace"
        buttonText="Create Workspace"
        subtitle={
          <Text
            variant="body2"
            css={{ fontWeight: "$regular", c: "$on_surface_medium" }}
          >
            Get started by creating your own workspace
          </Text>
        }
        disableButton={invalidName}
        mainFunction={() => {
          AppAnalytics.track("btn.clicked", {
            btnId: "workspace.create",
            componentId: "modal",
          });
          toggleCreateDialog();
          setOpen(false);
          dispatch(createWorkspace(newWorkspace));
        }}
        variant="primary"
        enableEnterToSubmit
        content={
          <Flex direction="column" css={{ mt: "$12" }}>
            <Text css={{ mb: "$4" }}>Workspace Name</Text>
            <Input
              autoFocus
              css={{ bg: "$surface_bright", r: "$0" }}
              placeholder="e.g. 100ms Inc"
              value={newWorkspace.name}
              onChange={event => {
                setNewWorkspace({ ...newWorkspace, name: event.target.value });
              }}
            />
            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                c: "$alert_error_default",
                mt: "$4",
                visibility: invalidName ? "visible" : "hidden",
              }}
            >
              {invalidName}
            </Text>
            <Text css={{ mt: "$2", mb: "$1" }}>Data Residency Region</Text>

            <ClusterRegionSelectBox />

            <Flex css={{ mt: "$8", mb: "$8" }}>
              <Box css={{ w: "$8", h: "$8", m: "$1 $6 0 0", color: "red" }}>
                <Checkbox.Root
                  checked={newWorkspace?.hipaa}
                  onCheckedChange={() => {
                    setNewWorkspace({
                      ...newWorkspace,
                      hipaa: !newWorkspace?.hipaa,
                    });
                  }}
                  css={{
                    borderRadius: "3px",
                    borderWidth: "$normal",
                    '&[data-state="unchecked"]': {
                      borderColor: "$secondary_default",
                      backgroundColor: "$transparent",
                    },
                    '&[data-state="checked"]': {
                      backgroundColor: "$primary_bright",
                      borderColor: "$primary_bright",
                    },
                  }}
                  id="deleteCheckBoxText"
                >
                  <Checkbox.Indicator>
                    <CheckIcon
                      width={15}
                      height={25}
                      style={{ color: "black" }}
                      fontWeight={800}
                    />
                  </Checkbox.Indicator>
                </Checkbox.Root>
              </Box>
              <Text css={{ c: "$on_surface_medium" }}>HIPAA Compliant</Text>
            </Flex>
            {newWorkspace?.hipaa ? (
              <WarningCard
                open={true}
                body="This is an irreversible action which will instrument
                changes and disable features in the new workspace. User is also
                required to sign a BAA with 100ms to be HIPAA compliant."
                title=" "
                hideCross={true}
                link={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/security-and-privacy/HIPAA%20compliance/HIPAA-workspace`}
              />
            ) : (
              <></>
            )}
          </Flex>
        }
      />
    </Flex>
  );
};

export default WorkspaceCollapsible;
