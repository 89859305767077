import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chat, Chat_ChatState } from "@100mslive/types-prebuilt/elements/chat";
import { isEmpty } from "lodash";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import StatusString from "src/components/Common/StatusString";
import { API_CALL_STATE } from "src/constants";
import { defaultConferencing } from "src/data/defaultLayout";
import { toTitleCase } from "src/helpers";
import { PrebuiltSidebarAccordionSection } from "src/pages/Prebuilt/PrebuiltSidebarAccordionSection";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { setHasUnsavedPrebuiltChanges } from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import { RoleLayouts } from "src/types/prebuilt";
import { Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import { PrebuiltSidebarInput } from "./PrebuiltSidebarInput";
import { PrebuiltSidebarMultiSelect } from "./PrebuiltSidebarMultiSelect";
import { PrebuiltSidebarSwitch } from "./PrebuiltSidebarSwitch";
import { validateChat } from "../validator/roomScreenValidations";

type Props = {
  data: Chat;
  setData: (data: Chat) => void;
  invalidFields: { [key in keyof Chat]?: string } | undefined;
  roleLayouts: RoleLayouts;
  role: string;
};

const chatInitialStateTypes = {
  CHAT_STATE_OPEN: "Open",
  CHAT_STATE_CLOSE: "Close",
};

function PrebuiltChatConfig({
  data,
  setData,
  invalidFields,
  role,
  roleLayouts,
}: Props) {
  const dispatch = useDispatch();
  const patchAppLayoutStatus = useSelector(
    (state: RootState) => state.appLayouts.patchAppLayoutStatus
  );
  const accordions = ["Chat UI", "Chat Controls"];
  const [activeAccordion, setActiveAccordion] = useState(accordions[0]);

  const chatUIInputs = [
    {
      title: "Panel Name",
      value: data?.chat_title || "",
      onChange: (value: string) => {
        setData({ ...data, chat_title: value });
      },
      errorMessage: invalidFields?.["chat_title"],
      maxLength: 25,
    },
    {
      title: "Message Input Field Placeholder",

      value: data?.message_placeholder || "",
      onChange: (value: string) =>
        setData({ ...data, message_placeholder: value }),
    },
  ];

  const chatControls = [
    {
      title: "Allow pinning messages",
      tooltipText: `${toTitleCase(
        role
      )} can pin and unpin messages in the chat.`,

      checked: !!data?.allow_pinning_messages,
      onCheckedChange: () =>
        setData({
          ...data,
          allow_pinning_messages: !data?.allow_pinning_messages,
        }),
    },
    {
      title: "Allow hiding messages",
      tooltipText: `${toTitleCase(
        role
      )} can hide specific messages in the chat for everyone.`,
      checked: !!data?.real_time_controls?.can_hide_message,
      onCheckedChange: () =>
        setData({
          ...data,
          real_time_controls: {
            ...data?.real_time_controls,
            can_hide_message: !data?.real_time_controls?.can_hide_message,
          },
        }),
    },
    {
      title: "Allow blocking peers in chat",
      tooltipText: `${toTitleCase(
        role
      )} can block specific peers in the chat from sending messages.`,
      checked: !!data?.real_time_controls?.can_block_user,
      onCheckedChange: () =>
        setData({
          ...data,
          real_time_controls: {
            ...data?.real_time_controls,
            can_block_user: !data?.real_time_controls?.can_block_user,
          },
        }),
    },
    {
      title: "Allow pausing chat",
      tooltipText: `${toTitleCase(
        role
      )} can pause and resume chat for everyone in
      real-time within a session. Supported on Web.`,
      checked: !!data?.real_time_controls?.can_disable_chat,
      onCheckedChange: () =>
        setData({
          ...data,
          real_time_controls: {
            ...data?.real_time_controls,
            can_disable_chat: !data?.real_time_controls?.can_disable_chat,
          },
        }),
    },
  ];

  const chatRBACOptions = [
    {
      title: "Public chat",
      tooltipText: `If enabled, this role can send mesages in the public chat`,
      checked: !!data?.public_chat_enabled,
      onCheckedChange: () =>
        setData({
          ...data,
          public_chat_enabled: !data?.public_chat_enabled,
        }),
    },
    {
      title: "Private chat",
      tooltipText: `If enabled, this role can send private messages to all the peers with the permission to send private messages`,
      checked: !!data?.private_chat_enabled,
      onCheckedChange: () =>
        setData({
          ...data,
          private_chat_enabled: !data?.private_chat_enabled,
        }),
    },
    {
      title: "Role-specific chat",
      tooltipText: `If enabled, this role can send messages to the below selected roles.`,
      checked: !!(data?.roles_whitelist && data?.roles_whitelist.length > 0),
      onCheckedChange: () => {
        const currentSelections = data?.roles_whitelist || [];

        const updatedSelections =
          currentSelections.length > 0 ? [] : Object.keys(roleLayouts);

        const index = updatedSelections.findIndex(r => r === role);

        if (index !== -1) {
          updatedSelections.splice(index, 1);
          updatedSelections.push(role);
        }
        setData({
          ...data,
          roles_whitelist: updatedSelections,
        });
      },
    },
  ];

  const disableOptions = patchAppLayoutStatus === API_CALL_STATE.IN_PROGRESS;

  useEffect(() => {
    if (!isEmpty(invalidFields)) {
      dispatch(setHasUnsavedPrebuiltChanges(false));
    }
  }, [invalidFields, dispatch]);

  return (
    <>
      <PrebuiltSidebarAccordionSection
        title={accordions[0]}
        isActive={accordions[0] === activeAccordion}
        setActiveAccordion={() => setActiveAccordion(accordions[0])}
      >
        {chatUIInputs.map(ChatUIInput => (
          <PrebuiltSidebarInput key={ChatUIInput.title} {...ChatUIInput} />
        ))}

        <PrebuiltSidebarItemWrapper
          title="Initial State"
          tooltipText={`The initial state of the chat panel when the ${toTitleCase(
            role
          )} joins`}
        >
          <ConfigSingleSelect
            inputText={
              chatInitialStateTypes[
                data?.initial_state as keyof typeof chatInitialStateTypes
              ]
            }
            triggerCSS={{ w: "98%", ml: "$1" }}
            contentCSS={{ zIndex: 1100 }}
            content={
              <>
                {Object.keys(chatInitialStateTypes).map(type => (
                  <Dropdown.Item
                    key={type}
                    css={{ p: "$4 $8" }}
                    onClick={() => {
                      const tempData = {
                        ...data,
                        initial_state: type as Chat_ChatState,
                      };
                      setData({
                        ...tempData,
                      });
                    }}
                  >
                    <Text variant="caption">
                      {
                        chatInitialStateTypes[
                          type as keyof typeof chatInitialStateTypes
                        ]
                      }
                    </Text>
                  </Dropdown.Item>
                ))}
              </>
            }
          />
          <StatusString content={invalidFields?.initial_state as string} />
        </PrebuiltSidebarItemWrapper>

        <PrebuiltSidebarSwitch
          title="Enable overlay view"
          tooltipText="Chat will be overlayed on top of the UI to create a more
                immersive experience. Only available on mobile."
          disabled={disableOptions}
          checked={!!data?.is_overlay}
          onCheckedChange={() =>
            setData({
              ...data,
              is_overlay: !data?.is_overlay,
            })
          }
        />
      </PrebuiltSidebarAccordionSection>

      <PrebuiltSidebarAccordionSection
        title={accordions[1]}
        isActive={accordions[1] === activeAccordion}
        setActiveAccordion={() => setActiveAccordion(accordions[1])}
      >
        {chatControls?.map(chatControlOption => (
          <PrebuiltSidebarSwitch
            key={chatControlOption.title}
            disabled={disableOptions}
            {...chatControlOption}
          />
        ))}
        <Flex css={{ mt: "$10", color: "$on_surface_low", gap: "$4" }}>
          <Text variant="sm" css={{ color: "$on_surface_medium" }}>
            Can send messages to
          </Text>
        </Flex>
        {chatRBACOptions?.map(chatRBACOption => (
          <PrebuiltSidebarSwitch
            key={chatRBACOption.title}
            disabled={disableOptions}
            {...chatRBACOption}
          />
        ))}

        {data?.roles_whitelist && data.roles_whitelist.length > 0 ? (
          <>
            <Text variant="xs" css={{ color: "$on_surface_medium", my: "$4" }}>
              Can send messages to roles
            </Text>
            <PrebuiltSidebarMultiSelect
              inputText="Select roles"
              optionsArray={Object.keys(roleLayouts || {})}
              selectedOptions={data?.roles_whitelist || []}
              onCheckedChange={(roleName: string) => {
                const currentSelections = data.roles_whitelist || [];
                const updatedSelections =
                  currentSelections.indexOf(roleName) === -1
                    ? [...currentSelections, roleName]
                    : currentSelections.filter(
                        selectedRole => selectedRole !== roleName
                      );
                setData({ ...data, roles_whitelist: updatedSelections });
              }}
              isCheckedCondition={(roleName: string) =>
                !!(
                  data?.roles_whitelist &&
                  data.roles_whitelist.indexOf(roleName) !== -1
                )
              }
            />
          </>
        ) : null}
      </PrebuiltSidebarAccordionSection>
    </>
  );
}

export default PrebuiltChatConfig;

const defaultData = defaultConferencing?.["default"]?.["elements"]?.["chat"];

export const prebuiltChatConfig = {
  ui: {
    title: "Chat",
    hasSwitch: true,
    tooltipText: "",
  },
  path: "screens.conferencing.default.elements.chat",
  Component: PrebuiltChatConfig,
  defaultData: defaultData,
  validator: validateChat,
};
